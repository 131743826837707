<template>
  <div id="div-medical-history">
    <b-card
      no-body
      border-variant="success"
    >
      <b-card-body>
        <h4>
          {{ t('title.Medical History') }}
          <br>
          <small>
            {{ t('title.Past Medical History') }}
          </small>
        </h4>
        <b-card-text>
          <div v-if="medicalHistories.length === 0">
            <div class="card border-secondary">
              <div class="card-body pb-0">
                <h5 class="text-primary text-center">
                  <feather-icon
                    class="mr-25 text-danger"
                    icon="HeartIcon"
                    size="20"
                  />
                  {{ t('title.No Data') }}
                </h5>
              </div>
            </div>
          </div>
          <swiper
            v-else
            class="swiper-responsive-breakpoints"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide
              v-for="(data, index) in medicalHistories"
              :key="index"
              class="pb-1"
            >
              <b-card
                no-body
                class="border-secondary"
              >
                <b-card-body class="pb-0">
                  <b-row>
                    <b-col>
                      <h3>{{ $helpers.getFullDate(data.enrollDate, $i18n.locale) }}</h3>
                    </b-col>
                    <b-col
                      cols="4"
                      class="text-right"
                    >
                      <b-badge
                        v-if="data.numOfDrug > 0"
                        variant="info"
                      >
                        {{ data.numOfDrug }}
                        <small v-if="data.numOfDrug > 1">
                          drugs
                        </small>
                        <small v-else>
                          drug
                        </small>
                      </b-badge>
                    </b-col>
                  </b-row>
                  <h5 class="text-nowrap">
                    <feather-icon
                      class="mr-25 text-danger"
                      icon="MapPinIcon"
                    />
                    {{ $i18n.locale === 'th' ? data.clinicName : data.clinicNameENG }}
                  </h5>
                  <h5 style="white-space: nowrap;  width: 90%;  overflow: hidden;  text-overflow: ellipsis;">
                    <feather-icon
                      class="mr-25 text-success"
                      icon="UserIcon"
                    />
                    {{ data.doctorName }}
                  </h5>
                  <h5>
                    <feather-icon
                      class="mr-25 text-primary"
                      icon="Navigation2Icon"
                    />
                    <span
                      v-if="data.typeCome === ''
                        || data.typeCome === null
                        || data.typeCome === 'W'"
                    >
                      {{ t('medical.W') }}
                    </span>
                    <span v-else-if="data.typeCome === 'F'">
                      {{ t('medical.F') }}
                    </span>
                    <span v-else-if="data.typeCome === 'IPD'">
                      {{ t('medical.IPD') }}
                    </span>
                  </h5>
                  <b-alert
                    v-if="data.pdDesc"
                    variant="primary"
                    show
                  >
                    <div
                      class="alert-body"
                      style="white-space: nowrap;  width: 100%;  overflow: hidden;  text-overflow: ellipsis;"
                    >
                      {{ data.pdCode !== null && data.pdCode !== '' ? $i18n.locale === 'th' ? data.pdDescTH.replaceAll('ไม่ระบุรายละเอียด','') : data.pdDesc.replaceAll('\\, unspecified','') : '-' }}
                    </div>
                  </b-alert>
                  <b-alert
                    v-else
                    variant="primary"
                    show
                  >
                    <div
                      class="alert-body"
                      style="white-space: nowrap;  width: 100%;  overflow: hidden;  text-overflow: ellipsis;"
                    >
                      <span class=" text-hide">1</span>
                    </div>
                  </b-alert>
                </b-card-body>
                <b-card-footer
                  class="text-light bg-primary pt-1 pb-1"
                  @click="showMedicalModal(data.txn)"
                >
                  {{ t('Button Detail') }}
                </b-card-footer>
              </b-card>
            </swiper-slide>
            <div
              v-if="medicalHistories.length > 1"
              slot="pagination"
              class="swiper-pagination"
            />
          </swiper>
          <b-link
            @click="changeRoute"
          >
            {{ t('Button More') }} >
          </b-link>
        </b-card-text>
      </b-card-body>

    </b-card>
    <b-modal
      id="medicalModal"
      ref="medicalModal"
      :hide-footer="true"
      :hide-header="true"
      centered
      size="xs"
    >
      <profile-medical-history-detail
        :txn="txn"
        @exit="onCloseModal"
      />
    </b-modal>
  </div>

</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ProfileMedicalHistoryDetail from '@/views/profile/ProfileMedicalHistoryDetail.vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    ProfileMedicalHistoryDetail,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  props: {
    medicalHistories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      txn: '',
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1440: {
            slidesPerView: 2.1,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 1.3,
            spaceBetween: 25,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1.1,
            spaceBetween: 15,
          },
          320: {
            slidesPerView: 1.1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
  methods: {
    changeRoute() {
      this.$router.push({ name: 'member-history' })
    },
    showMedicalModal(txn) {
      this.txn = txn
      this.$refs.medicalModal.show()
    },
    onCloseModal() {
      this.$refs.medicalModal.hide()
    },
  },
}
</script>
