<template>
  <b-card v-if="visitInfo">
    <b-row>
      <b-col
        cols="12"
        md="5"
        class="mb-1"
      >
        <strong>Wi-Fi <h6>spwifi, spwifi5-GHz</h6></strong>
      </b-col>
      <b-col v-if="visitInfo.usernameWifi">
        <b-form-group :description="t('wifi.non opd')">
          <b-input-group>
            <b-form-input
              v-model="visitInfo.usernameWifi"
              readonly
              autocomplete="off"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="doCopy"
              >
                <feather-icon
                  class="mr-25"
                  icon="ClipboardIcon"
                />
                Copy
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col v-else>
        <small>{{ t('wifi.non registered') }}</small>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  props: {
    visitInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  methods: {
    doCopy() {
      this.$copyText(this.visitInfo.usernameWifi).then(() => {
        this.$bvToast.toast('Text copied', {
          title: 'Copy Username Wi-Fi',
          solid: true,
          variant: 'primary',
          toaster: 'b-toaster-top-center',
          appendToast: true,
        })
      // eslint-disable-next-line no-unused-vars
      }, e => {
        this.$bvToast.toast('Can not copy!', {
          title: 'Copy Username Wi-Fi',
          solid: true,
          variant: 'warning',
          toaster: 'b-toaster-top-center',
          appendToast: true,
        })
      })
    },
  },
}
</script>

<style>

</style>
