<template>
  <div
    id="div-vital-sign-body-mass"
  >
    <b-card
      style="background-color: #219E83;"
      no-body
    >
      <b-card-body>
        <h4 class="text-white pb-1">
          {{ t('title.Vital Sign History') }}
        </h4>
        <b-card-text>
          <div
            v-if="!vitalSignBodyMass"
            class="text-center"
          >
            <b-spinner variant="danger" />
          </div>
          <b-row v-else>
            <b-col
              cols="6"
              sm="12"
              md="6"
              xl="3"
            >
              <b-card
                no-body
                class="p-0"
              >
                <b-card-text class="p-0">
                  <h6 class="pt-1 pl-1 text-nowrap">
                    <feather-icon
                      class="mr-10 text-warning"
                      icon="ThermometerIcon"
                    />
                    {{ t('medical.temp') }}
                  </h6>
                  <div v-if="!vitalSignBodyMass.tempInfos">
                    <h3 class="pt-1 mb-0 text-muted">
                      No Data
                    </h3>
                    <h6>&nbsp;</h6>
                    <small>&nbsp;</small>
                  </div>
                  <div
                    v-else
                    class=""
                    @click="$router.push('/medical-vital-sign-body-mass/temp').catch(() => {})"
                  >
                    <h2 class="mb-0 text-center font-large-3">
                      {{ vitalSignBodyMass.tempInfos[0].temp }}
                    </h2>
                    <h6 class="text-right pr-1">
                      <sup>o</sup>C
                    </h6>
                    <h6 class="text-center text-nowrap text-muted">
                      {{ $helpers.getFullDate(vitalSignBodyMass.tempInfos[0].dateProvider.substring(0, 10), this.$i18n.locale) }}
                    </h6>
                  </div>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col
              cols="6"
              sm="12"
              md="6"
              xl="3"
            >
              <b-card
                no-body
                class="p-0"
              >
                <b-card-text class="p-0">
                  <h6 class="pt-1 pl-1 text-nowrap">
                    <feather-icon
                      class="mr-10 text-success"
                      icon="HeartIcon"
                    />
                    {{ t('medical.pulse') }}
                  </h6>
                  <div v-if="!vitalSignBodyMass.pulseInfos">
                    <h3 class="pt-1 mb-0 text-muted">
                      No Data
                    </h3>
                    <h6>&nbsp;</h6>
                    <small>&nbsp;</small>
                  </div>
                  <div
                    v-else
                    @click="$router.push('/medical-vital-sign-body-mass/pulse').catch(() => {})"
                  >
                    <h2 class="mb-0 text-center font-large-3">
                      {{ vitalSignBodyMass.pulseInfos[0].pulse }}
                    </h2>
                    <h6 class="text-right pr-1">
                      bpm
                    </h6>
                    <h6 class="text-center text-nowrap text-muted">
                      {{ $helpers.getFullDate(vitalSignBodyMass.pulseInfos[0].dateProvider.substring(0, 10), this.$i18n.locale) }}
                    </h6>
                  </div>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col
              cols="6"
              sm="12"
              md="6"
              xl="3"
            >
              <b-card
                no-body
                class="p-0"
              >
                <b-card-text class="p-0">
                  <h6 class="pt-1 pl-1 text-nowrap">
                    <feather-icon
                      class="mr-10 text-danger"
                      icon="DropletIcon"
                    />
                    {{ t('medical.bp') }}
                  </h6>
                  <div v-if="!vitalSignBodyMass.systolicInfos">
                    <h3 class="pt-1 mb-0 text-muted">
                      No Data
                    </h3>
                    <h6>&nbsp;</h6>
                    <small>&nbsp;</small>
                  </div>
                  <div
                    v-else
                    @click="$router.push('/medical-vital-sign-body-mass/bp').catch(() => {})"
                  >
                    <h3 class="mb-0 text-center font-large-1">
                      {{ vitalSignBodyMass.systolicInfos[0].systolic }}/{{ vitalSignBodyMass.diastolicInfos[0].diastolic }}
                    </h3>
                    <h6 class="text-muted text-right">
                      mm/Hg
                    </h6>
                    <h6 class="text-center text-nowrap text-muted">
                      {{ $helpers.getFullDate(vitalSignBodyMass.systolicInfos[0].dateProvider.substring(0, 10), this.$i18n.locale) }}
                    </h6>
                  </div>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col
              cols="6"
              sm="12"
              md="6"
              xl="3"
            >
              <b-card
                no-body
                class="p-0"
              >
                <b-card-text class="p-0">
                  <h6 class="pt-1 pl-1 text-nowrap">
                    <feather-icon
                      class="mr-10 text-info"
                      icon="UserIcon"
                    />
                    {{ t('medical.bmi') }}
                  </h6>
                  <div v-if="!vitalSignBodyMass.bodyMassInfos">
                    <h3 class="pt-1 mb-0 text-muted">
                      No Data
                    </h3>
                    <h6>&nbsp;</h6>
                    <small>&nbsp;</small>
                  </div>
                  <div
                    v-else
                    @click="$router.push('/medical-vital-sign-body-mass/bmi').catch(() => {})"
                  >
                    <h2 class="mb-0 text-center font-large-1">
                      {{ vitalSignBodyMass.bodyMassInfos[0].bmi }}
                    </h2>
                    <h6>&nbsp;</h6>
                    <h6 class="text-center text-nowrap text-muted">
                      {{ $helpers.getFullDate(vitalSignBodyMass.bodyMassInfos[0].dateProvider.substring(0, 10), this.$i18n.locale) }}
                    </h6>
                  </div>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-card-text>
        <b-link
          class="pb-1 text-white"
          @click="changeRoute"
        >
          {{ t('Button More') }} >
        </b-link>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  props: {
    vitalSignBodyMass: {
      type: Array(Object),
      default: () => [],
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  methods: {
    changeRoute() {
      this.$router.push({ name: 'member-vital-sign-body-mass' })
    },
  },
}
</script>
