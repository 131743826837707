<template>
  <div>
    <b-alert
      variant="danger"
      show
    >
      <div class="alert-body">
        <b-row>
          <b-col cols="8">
            <b-img
              :src="require('@/assets/images//icons/drug-allergy.png')"
              class=""
              height="32"
              alt="Toast image"
            />
            <span> {{ t('title.Drug Allergy') }}</span>
          </b-col>
          <b-col class="d-flex align-items-center justify-content-end">
            <!-- <feather-icon
              class="mr-25 text-danger"
              icon="AlertCircleIcon"
              @click="showModalAllergy"
            /> -->
            <b-button
              class=""
              variant="warning"
              size="sm"
              @click="showModalAllergy"
            >
              {{ t('View') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
    <b-modal
      id="allergyModal"
      ref="allergyModal"
      :hide-footer="true"
      centered
      size="lg"
      title="Allergy"
    >
      <b-card-text>
        <div
          v-for="(data, index) in drugAllergy"
          :key="index"
        >
          <b-card
            :style="`${cardBgVariant(data.warn)}`"
            no-body
          >
            <b-card-body>
              <b-card-text>
                <b-card
                  v-if="data.warn === '0'"
                  bg-variant="danger"
                  style="padding: 0.5rem"
                  no-body
                >
                  <b-card-text class="text-center text-white">
                    <small>
                      ผู้ป่วยแพ้ยา
                    </small>
                  </b-card-text>
                </b-card>
                <b-card
                  v-else
                  bg-variant="warning"
                  style="padding: 0.5rem"
                  no-body
                >
                  <b-card-text class="text-center text-white">
                    <small>
                      คำเตือนอาการไม่พึงประสงค์จากการใช้ยา
                    </small>
                  </b-card-text>
                </b-card>
                <div
                  v-html="data.sym.replace(/\n/g, '<br>')"
                />
              </b-card-text>
            </b-card-body>
          </b-card>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  props: {
    drugAllergy: {
      type: Array,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      patientMain: JSON.parse(localStorage.getItem('patientTempData')),
      patientInfo: JSON.parse(localStorage.getItem('patientData')),
    }
  },
  methods: {
    showModalAllergy() {
      // console.log('Show', this.drugAllergy)
      this.$refs.allergyModal.show()
    },
    cardBgVariant(warn) {
      // console.log(warn)
      return warn === '0' ? 'background-color: #FFB0B0; text-color:white;' : 'background-color: #FFFAB7; text-color:black;'
    },
  },
}
</script>

<style>

</style>
