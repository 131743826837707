<template>
  <div>
    <!-- Page 1 -->
    <div
      v-if="submit === false"
      class="text-center"
    >
      <h6>{{ t('title.rating') }}</h6>
      <h1 class="text-bold mt-1">
        {{ labelWording }}
      </h1>
      <b-form-rating
        v-model="ratingScore"
        no-border
        variant="warning"
        size="lg"
      />
      <b-button
        v-if="ratingScore > 0"
        variant="primary"
        block
        @click="setSubmit"
      >
        {{ t('rating.submit') }}
      </b-button>
      <b-button
        v-else
        variant="primary"
        block
        disabled
      >
        {{ t('rating.submit') }}
      </b-button>
    </div>
    <div
      v-else-if="submit === true"
      class="text-center"
    >
      <!-- Page 2 -->
      <div v-if="send === false">
        <h6>{{ t('rating.thank you') }}</h6>
        <b-form-rating
          v-model="ratingScore"
          no-border
          variant="warning"
          size="lg"
        />
        <b-form-textarea
          v-model="comment"
          :placeholder="t('rating.placeholder')"
          class="mb-1"
          no-resize
          :state="comment.length >= 6"
        />
        <div class="text-right mb-1">
          <span>{{ t('rating.lasted') }} {{ $helpers.getFullDate(ratingDate, $i18n.locale) }}</span>
        </div>
        <b-button
          v-if="comment.length >= 6"
          variant="primary"
          block
          class="mb-1"
          @click="setSend"
        >
          {{ t('rating.send feedback') }}
        </b-button>
        <b-button
          v-else
          variant="primary"
          block
          class="mb-1"
          disabled
        >
          {{ t('rating.send feedback') }}
        </b-button>

        <b-link @click="rated">
          {{ t('rating.skip') }}
        </b-link>
      </div>

      <!-- Page 3 -->
      <div v-else>
        <!-- <b-img
          :src="require('@/assets/images/logo/rated-logo.png')"
          class=""
          height="150"
          alt="Toast image"
        /> -->
        <iframe
          frameBorder="0"
          width="150"
          src="https://embed.lottiefiles.com/animation/106805"
        />
        <div class="mb-1">
          <h6>{{ t('rating.thank you') }}</h6>
        </div>
        <b-link @click="rated">
          {{ t('rating.back to main page') }}
        </b-link>
        <br>
        <span>
          {{ t('rating.close') }}
        </span>
      </div>
    </div>

  </div>

</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      submit: false,
      send: false,
      ratingScore: 0,
      ratingDate: '',
      comment: '',
      patientInfo: {},
    }
  },
  computed: {
    labelWording() {
      switch (this.ratingScore) {
        case 1:
          return `${this.$t('rating.1star')}`
        case 2:
          return `${this.$t('rating.2star')}`
        case 3:
          return `${this.$t('rating.3star')}`
        case 4:
          return `${this.$t('rating.4star')}`
        case 5:
          return `${this.$t('rating.5star')}`
        default:
          return '-'
      }
    },
  },
  created() {
    this.patientInfo = JSON.parse(localStorage.getItem('patientData'))
    this.getRating()
  },
  methods: {
    setSubmit() {
      this.submit = !this.submit
      this.rating()
    },
    setSend() {
      this.send = !this.send
      this.rating()
      setTimeout(() => this.rated(), 5000)
    },
    rated() {
      this.$emit('rating', true)
    },
    rating() {
      this.$http({
        url: 'http://phrapi.sriphat.com/api/UserAuthen/RattingPHR',
        method: 'POST',
        data: {
          RattingScore: this.ratingScore,
          Comment: this.comment,
          HN: this.patientInfo.hn,
        },
      })
    },
    getRating() {
      this.$http({
        url: `http://phrapi.sriphat.com/api/UserAuthen/RattingPHR/${this.patientInfo.hn}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        this.comment = data.comment
        this.ratingScore = data.rattingScore
        this.ratingDate = data.updateDateTime
      })
    },
  },
}
</script>

<style>

</style>
