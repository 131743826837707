<template>
  <div id="member-card">
    <div
      v-if="visitInfo.listDoctorDetail !== null && visitInfo.listDoctorDetail !== undefined && visitInfo.listDoctorDetail !== ''"
    >
      <center>
        <b-img
          :src="require('@/assets/images/logo/sp_logo_white.png')"
          height="40"
          alt="Toast image"
        />
      </center>
      <h4 class="text-center mt-1">
        <span class="font-weight-bold">
          VisitSlip
        </span>
      </h4>
      <b-card
        no-body
        class="mb-1"
        border-variant="primary"
      >
        <b-card-text>
          <b-row>
            <b-col
              cols="12"
              class="text-right pr-2"
            >
              <h2 class="text-primary">VN : {{ visitInfo.vn }}</h2>
            </b-col>
            <b-col
              cols="12"
              class="text-center"
            >
              <h3 class="text-primary">
                {{ visitInfo.fullName }}
              </h3>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              {{ t('patient.hn') }} :
            </b-col>
            <b-col cols="6">
              {{ visitInfo.hn }}
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              {{ t('patient.idCard') }}:
            </b-col>
            <b-col cols="6">
              {{ patientInfo.idCard }}
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              {{ t('patient.dob') }} :
            </b-col>
            <b-col cols="6">
              {{ $helpers.getFullDate(patientInfo.born, $i18n.locale) }}
            </b-col>
            <b-col
              cols="12"
              class="mb-1 mt-1"
            >
              <h6 class="text-center text-success">
                Payor : {{ visitInfo.listDoctorDetail[0].payorName }}
              </h6>
              <h6 class="text-center text-success">
                Plan : {{ visitInfo.listDoctorDetail[0].planName }}
              </h6>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <center>
                <qr-code
                  :text="qrVisitData"
                  error-level="H"
                  :size="qrVisitSize"
                />
              </center>
            </b-col>
            <b-col cols="12">
              <b-list-group
                v-for="(data, index) in visitInfo.listDoctorDetail"
                :key="index"
                flush
              >
                <b-list-group-item>
                  {{ data.queue }} {{ data.doctorName }} <br>
                  <small>{{ data.clinicName }}</small>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
      <h6 class="text-center text-primary">
        ~ ENJOY FREE WIFI ~
      </h6>
      <h6 class="text-center">
        Hotspot: spwifi, spwifi5-GHz"
      </h6>
      <h6 class="text-center">
        UserName:
        <span class="font-weight-bold text-primary">
          {{ visitInfo.usernameWifi }}
        </span>
      </h6>
    </div>
    <div
      v-if="loadingQR"
      class="text-center"
    >
      <b-spinner variant="primary" />
    </div>
    <div v-else-if="!loadingQR && visitInfo.listDoctorDetail === null">
      <b-card border-variant="primary">
        <b-card-text class="text-center">
          <center>
            <qr-code
              :text="qrVisitData"
              error-level="H"
              :size="qrVisitSize"
            />
          </center>
        </b-card-text>
      </b-card>
    </div>
    <div class="mt-1 text-center text-success">
      {{ t('adjust brightness') }}
    </div>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import qrCode from 'vue-qrcode-component'

export default {
  components: {
    qrCode,
  },
  directives: {
    Ripple,
  },
  props: {
    patientInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      visitInfo: [],
      qrVisitSize: 200,
      qrVisitData: '',
      loadingQR: false,
    }
  },
  created() {
    this.loadVisitDetail()
  },
  methods: {
    loadVisitDetail() {
      this.visitInfo = []
      this.loadingQR = true
      // const testHn = '0677908'
      this.$http({
        url: `http://api159.sriphat.com/api/Enroll/VisitSlipDetailDecode/${this.patientInfo.hn}`,
        // url: `http://api159.sriphat.com/api/Enroll/VisitSlipDetailDecode/${testHn}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        // console.log(data)
        this.visitInfo = data
        this.qrVisitData = `V,${this.visitInfo.vn},${this.visitInfo.hn},${this.$helpers.getSQLCurDate()}`
        this.loadingQR = false
      }).catch(error => {
        console.log('loadVisitDetail', error)
      })
    },
  },
}
</script>
