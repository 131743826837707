<template>
  <div id="div-checkup">
    <b-link
      :href="url"
      target="_blank"
    >
      <b-card
        stretch
        overlay
        :img-src="require('@/assets/images/banner/checkupReport.png')"
      />
    </b-link>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  directives: {
    Ripple,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      // patientData: JSON.parse(localStorage.getItem('patientData')),
      hn: '',
      idcard: '',
      url: '',
    }
  },
  created() {
    this.getCheckupBtn()
  },
  methods: {
    getCheckupBtn() {
      // if (localStorage.getItem('patientView')) {
      //   this.idcard = localStorage.getItem('patientView')
      // } else if (localStorage.getItem('patientTempData')) {
      //   this.idcard = JSON.parse(localStorage.getItem('patientTempData')).idCard
      // }
      this.hn = JSON.parse(localStorage.getItem('patientData')).hn
      this.$http({
        // url: `http://192.168.100.56/api/Patient/UrlByIDCard/${this.idcard}`,
        // url: `http://phrapi.sriphat.com/api/Patient/UrlByIDCard/${this.idcard}`,
        url: `http://phrapi.sriphat.com/api/Patient/UrlCheckUpReportByHN/${this.hn}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        this.url = data
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>
