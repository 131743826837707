<template>
  <b-alert
    v-if="patientMain.idCard !== patientInfo.idCard"
    variant="dark"
    show
  >
    <div class="alert-body">
      <!-- {{ patientInfo.idCard }} {{ patientMain.idCard }} -->
      <b-row>
        <b-col cols="8">
          <span> {{ t('title.View As') }}</span>
        </b-col>
        <b-col class="d-flex align-items-center justify-content-end">
          <b-button
            class=""
            variant="dark"
            size="sm"
            @click="changeView"
          >
            {{ t('title.Leave View As') }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-alert>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      patientMain: JSON.parse(localStorage.getItem('patientTempData')),
      patientInfo: JSON.parse(localStorage.getItem('patientData')),
    }
  },
  methods: {
    changeView() {
      // localStorage.setItem('patientView', this.patientView.idCard)
      // let patientViewID = localStorage.getItem('patientData')
      // console.log('viewID', patientViewID, this.patientInfo)
      // if (patientViewID === null || patientViewID === undefined || patientViewID === '') {
      // const idCard = this.patientMain.idCard
      // }
      // const patientMain = JSON.parse(localStorage.getItem('patientTempData'))
      this.$http({
        url: `http://phrapi.sriphat.com/api/Patient/GetPatientDetail/${this.patientMain.idCard}/null`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        if (data.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          // this.patientData = data[0]
          // Update patient view to patientData
          // if (this.patientInfo.idCard !== patientViewID) {
          localStorage.setItem('patientView', this.patientMain.idCard)
          localStorage.setItem('patientData', JSON.stringify(data[0]))
          // this.loadPatientImage(data[0].hn)
          // }
          // localStorage.setItem('patientView', viewIdCard)
          // window.location.reload()
          if (this.$router.currentRoute.fullPath === '/home') {
            this.$router.go(this.$router.currentRoute)
          } else {
            this.$router.push({ name: 'member-home' })
          }
        }
      }).catch(error => {
        console.log('loadProfile', error)
      })
    },
  },
}
</script>

<style>

</style>
