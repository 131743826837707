<template>
  <div
    id="appointment-card"
  >
    <!-- <center>
      <b-img
        :src="require('@/assets/images/logo/sp_logo_white.png')"
        height="80"
        alt="Toast image"
      />
    </center> -->
    <b-row class="mt-1">
      <b-col>
        <h4>
          {{ $t('title.Appointment') }}
        </h4>
      </b-col>
      <b-col class="text-right">
        <h4 class="text-primary">
          {{ $helpers.getFullDate(appointmentInfo.date, this.$i18n.locale) }}
        </h4>
        <h5 class="text-primary">
          {{ appointmentInfo.time === appointmentInfo.timE1 ? appointmentInfo.time : `${appointmentInfo.time} - ${appointmentInfo.timE1}` }}
        </h5>
      </b-col>
    </b-row>
    <div class="mt-1">
      <b-alert
        variant="success"
        show
      >
        <div
          class="alert-body"
        >
          <strong>{{ $t('clinic') }} <h6> {{ appointmentInfo.clinicName }}</h6></strong>
          <strong>{{ $t('doctor') }} <h6> {{ appointmentInfo.doctorName.replaceAll('*','') }}</h6></strong>
        </div>
      </b-alert>
      <b-alert
        variant="primary"
        show
      >
        <div
          class="alert-body"
        >
          {{ appointmentInfo.comeForText }}
        </div>
      </b-alert>
      <b-alert
        v-if="appointmentInfo.appNote"
        variant="dark"
        show
      >
        <div
          class="alert-body"
        >
          {{ appointmentInfo.appNote }}
        </div>
      </b-alert>
      <b-alert
        v-else
        variant="dark"
        show
      >
        <div
          class="alert-body"
        >
          <span class="text-hide">1</span>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    Ripple,
  },
  props: {
    appointmentInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      patientData: JSON.parse(localStorage.getItem('patientData')),
      uRegister: '999999',
      pRegister: 'a027ec',
      loadingRegister: false,
    }
  },
  methods: {
    confirmRegister() {
      // const today = new Date()
      // const currentDay = `0${today.getDate()}`.substr(-2)
      // const currentTimeHour = `0${today.getHours()}`.substr(-2)
      this.$swal({
        title: 'Are you sure?',
        text: `${this.$t('title.Confirm Register')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.checkEnrollFromFollowup()
        }
      })
    },
    checkEnrollFromFollowup() {
      this.loadingRegister = true
      const curDate = this.$helpers.getSQLCurDate()
      const curTimeHour = (new Date()).getHours()
      // Get All Appointment
      this.$http({
        url: 'http://api159.sriphat.com/api/Appointment/OldAppointmentByFilter',
        method: 'POST',
        data: {
          HN: this.appointmentInfo.hn,
          DateApp: curDate,
          DOC: '',
        },
      }).then(({ data }) => {
        // console.log('Appoinntment', data)
        const followUpLists = data
        for (let i = 0; i < data.length; i += 1) {
          if (data[i].ok === 0 || data[i].ok === 1) {
            // Check Appointment By Current Time
            const appointmentStart = this.$helpers.IntNull(followUpLists[i].time.substr(0, 2))
            const appointmentEnd = this.$helpers.IntNull(followUpLists[i].timE1.substr(0, 2))
            // console.log(curTimeHour, appointmentStart, appointmentEnd, (appointmentStart <= curTimeHour || (appointmentStart >= curTimeHour && appointmentEnd >= curTimeHour)))
            if (appointmentStart <= curTimeHour || (appointmentStart >= curTimeHour && appointmentEnd >= curTimeHour)) {
              // console.log('Get Doctor Schedule')
              this.$http({
                url: 'http://api159.sriphat.com/api/DoctorSchedule/GetOldSchedule',
                method: 'POST',
                data: {
                  DoctorCode: followUpLists[i].doc,
                  Location: followUpLists[i].room,
                  date: curDate,
                },
              }).then(({ data }) => {
                console.log('schedule', data.length, data)
                if (data.length > 0) {
                  let registerComplete = false
                  for (let s = 0; s < data.length; s += 1) {
                    // console.log(data[s])
                    const periodHour = this.$helpers.IntNull(data[s].schd_st_time.substr(0, 2))
                    // const periodMin = this.$helpers.IntNull(data[s].schd_end_time.substr(3, 2))
                    // console.log(appointmentStart, periodHour, appointmentEnd, periodHour, data[s].schd_ser, followUpLists[i].schd_ser, (appointmentStart >= periodHour && appointmentEnd >= periodHour && data[s].schd_ser === followUpLists[i].schd_ser))
                    if (appointmentStart >= periodHour && appointmentEnd >= periodHour && data[s].schd_ser === followUpLists[i].schd_ser) {
                      console.log('Register', data[s].schd_ser, followUpLists[i].schd_ser)
                      this.createEnrollFromFollowup(this.$helpers.IntNull(followUpLists[i].comefor), followUpLists[i].doc, followUpLists[i].room, followUpLists[i].appNote, 0, followUpLists[i].ser, followUpLists[i].schd_ser, followUpLists[i].inst_rowid, followUpLists[i].auxit_rowid)
                      registerComplete = true
                    }
                  }
                  if (!registerComplete) {
                    console.log('Register Out Schedule')
                    this.createEnrollFromFollowup(this.$helpers.IntNull(followUpLists[i].comefor), followUpLists[i].doc, followUpLists[i].room, followUpLists[i].appNote, 0, followUpLists[i].ser, 0, followUpLists[i].inst_rowid, followUpLists[i].auxit_rowid)
                  }
                } else {
                  console.log('Register No Schedule')
                  this.createEnrollFromFollowup(this.$helpers.IntNull(followUpLists[i].comefor), followUpLists[i].doc, followUpLists[i].room, followUpLists[i].appNote, 0, followUpLists[i].ser, 0, followUpLists[i].inst_rowid, followUpLists[i].auxit_rowid)
                }
              }).catch(error => {
                console.log('GetOldSchedule', error)
              })
            }
          }
        }
        // console.log(this.registerList)
      }).catch(error => {
        console.log('Get Appointment CurDate', error)
      })
      // Close Modal
      setTimeout(() => this.$emit('exit', 'appointmentModal'), 3000)
    },
    createEnrollFromFollowup(comeFor, doctorCode, clinic, note, scheduleID, appointmentID, oldScheduleID, payorID, planID) {
      this.$http({
        url: 'http://api159.sriphat.com/api/LoginPatient/login-Employee',
        method: 'POST',
        data: {
          Username: this.uRegister,
          Password: this.pRegister,
        },
      }).then(({ data }) => {
        useJwt.setToken(data.token)
        // Get Clinic ID
        let clinicID = 0
        this.$http({
          url: `http://phrapi.sriphat.com/api/Customer_Health_Record_/ClinicIDByClinicCode/${clinic}`,
          method: 'GET',
          data: {},
        }).then(({ data }) => {
          // console.log('ClinicID', clinic, data)
          clinicID = data
          this.$http({
            url: 'http://api159.sriphat.com/api/DoctorSchedule/GetDoctorCodeID',
            method: 'POST',
            data: {
              DocCode: doctorCode,
              ClinicCode: clinic,
            },
          }).then(({ data }) => {
            // console.log(data)
            this.$http({
              url: 'http://api159.sriphat.com/api/Enroll/OldEnroll',
              method: 'POST',
              data: {
                Service_Event: {
                  ComeFor: comeFor,
                  DoctorCodeID: data,
                  ClinicID: clinicID,
                  PayorID: payorID,
                  PlanID: planID,
                  ServiceType: 2,
                  EnrollNote: note,
                },
                ScheduleID: scheduleID,
                QueueNum: '',
                QueueType: 'OPD',
                HN: this.appointmentInfo.hn,
                AppointmentID: appointmentID,
                OldScheduleID: oldScheduleID,
              },
            }).then(({ data }) => {
              console.log(data)
            }).catch(error => {
              console.log('Register', error)
            })
          }).catch(error => {
            console.log('GetDoctorCodeID', error)
          })
        }).catch(error => {
          console.log('ClinicIDByClinicCode', error)
        })
      }).catch(error => {
        console.log('Login', error)
      })
    },
  },
}
</script>
