<template>
  <div v-if="visitInfo.listDoctorDetail !== null && visitInfo.listDoctorDetail !== undefined && visitInfo.listDoctorDetail !== ''">
    <h4>
      {{ t('title.Today') }}
    </h4>
    <swiper
      class="swiper-responsive-breakpoints"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(data, index) in visitInfo.listDoctorDetail"
        :key="index"
      >
        <b-card
          border-variant="primary"
          no-body
        >
          <b-card-body
            class="text-righ"
          >
            <h4>
              <b-badge
                v-if="data.queue && data.meetDoctorFlag"
                class="text-wrap"
                variant="primary"
              >
                {{ data.numberOfWaiting === 0 ? `${t('patient.no previous queue')}` : `${data.numberOfWaiting} ${t('patient.queue before you')}` }}
              </b-badge>
              <b-badge
                v-else
                variant="primary"
              >
                {{ t('title.Confirm Registered') }}
              </b-badge>
            </h4>

            <h3 class="mt-1">
              VN {{ visitInfo.vn }}
            </h3>
            <h4>
              {{ $i18n.locale === 'th' ? data.clinicName : data.clinicNameEng }}
            </h4>
            <hr>
            <h6 v-if="data.queue">
              <feather-icon
                class="mr-25 text-primary"
                icon="ClockIcon"
              /> {{ data.queue }}
            </h6>
            <h6>
              <feather-icon
                class="mr-25 text-primary"
                icon="UserIcon"
              />
              {{ data.doctorName.trim() }}
            </h6>
            <h6>
              <feather-icon
                class="mr-25 text-primary"
                icon="CreditCardIcon"
              />
              {{ data.payorName }}
            </h6>
            <!-- <b-badge variant="info">
              <feather-icon
                class="mr-25 text-white"
                icon="CreditCardIcon"
              />
              {{ data.planName.replaceAll('OPD','').replaceAll('IPD','') }}
            </b-badge> -->
            <h6 v-if="!data.queue">
              <br>
            </h6>
          </b-card-body>
        </b-card>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  directives: {
    Ripple,
  },
  components: {
    Swiper,
    SwiperSlide,
    // ProfileAppointmentCard,
  },
  props: {
    visitInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      qrVisitSize: 200,
      qrVisitData: '',
      loadingQR: false,
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1440: {
            slidesPerView: 2.1,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 1.1,
            spaceBetween: 25,
          },
          768: {
            slidesPerView: 1.1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1.1,
            spaceBetween: 15,
          },
          320: {
            slidesPerView: 1.1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
}
</script>

<style>

</style>
