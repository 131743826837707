<template>
  <div id="div-follow">
    <h4>
      {{ t('title.Appointment') }}
      <br>
      <small>
        {{ t('title.Tab to see more detail') }}
      </small>
    </h4>
    <div v-if="appointments.length === 0">
      <b-card
        class="border-secondary"
        no-body
      >
        <b-card-body>
          <h5 class="text-mute text-center">
            <feather-icon
              class="mr-25 text-danger"
              icon="SmileIcon"
              size="20"
            />
            {{ t('title.No Data') }}
          </h5>
        </b-card-body>
      </b-card>
    </div>
    <swiper
      v-else
      class="swiper-responsive-breakpoints"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(data, index) in appointments"
        :key="index"
        class="pb-1"
      >
        <b-card
          no-body
          class="border-secondary"
          @click="showAppointmentInfo(data)"
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                sm="6"
              >
                <b-card
                  no-body
                  text-variant="primary"
                  border-variant="secondary"
                  style="background-color:rgba(247, 238, 252, 1)"
                  class="p-0 m-0"
                >
                  <b-card-text class="p-1">
                    <div class="d-flex flex-row">
                      <h1 class="text-primary pl-2 pr-3 font-large-4">
                        {{ data.date.substr(8, 2) }}
                      </h1>
                      <div class="pt-1">
                        <h2 class="text-primary">
                          {{ $helpers.getMonthName(data.date.substr(5, 2), $i18n.locale) }}
                        </h2>
                        <h2 class="text-primary">
                          {{ $helpers.getYearName(data.date.substr(0, 4), $i18n.locale) }}
                        </h2>
                      </div>
                    </div>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col
                cols="12"
                sm="6"
              >
                <div class="text-small mt-1">
                  <feather-icon
                    class="mr-25 text-danger"
                    icon="ClockIcon"
                  />
                  {{ data.time === data.timE1 ? data.time : `${data.time} - ${data.timE1}` }}
                </div>
                <div class="text-small">
                  <feather-icon
                    class="mr-25 text-warning"
                    icon="MapPinIcon"
                  />
                  {{ $i18n.locale === 'th' ? data.clinicName : data.clinicNameENG }}
                </div>
                <div class="text-small">
                  <feather-icon
                    class="mr-25 text-success"
                    icon="UserIcon"
                  />
                  {{ data.doctorName.replaceAll('*','') }}
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <b-alert
            v-if="data.ok === 2"
            variant="primary"
            show
          >
            <div class="alert-body text-center">
              <span><strong>{{ t('title.Registered') }}</strong></span>
            </div>
          </b-alert>

          <div
            v-if="loadingRegister"
            class="text-center"
          >
            <b-spinner variant="primary" />
          </div>
          <b-card-footer
            v-if="(data.ok === 1 || data.ok === 0)
              && data.date.substr(0, 10) === new Date().toJSON().substring(0, 10)
              && $helpers.IntNull(new Date().getHours()) >= 8
              && $helpers.IntNull(new Date().getHours()) <= 18"
            class="text-light bg-primary pt-1 pb-1"
            @click="confirmRegister"
          >
            {{ t('Button Register') }}
          </b-card-footer>
          <small
            v-if="(data.ok === 1 || data.ok === 0)
              && data.date.substr(0, 10) === new Date().toJSON().substring(0, 10)
              && $helpers.IntNull(new Date().getHours()) >= 8
              && $helpers.IntNull(new Date().getHours()) <= 18"
            class="text-mute d-flex justify-content-end"
          >
            {{ t('appointment.hint') }}
          </small>
        </b-card>
      </swiper-slide>
      <div
        v-if="appointments.length > 1"
        slot="pagination"
        class="swiper-pagination"
      />
    </swiper>
    <b-modal
      id="appointmentModal"
      ref="appointmentModal"
      :hide-footer="true"
      :hide-header="true"
      centered
      size="xs"
    >
      <profile-appointment-card
        :appointment-info="appointmentInfo"
        @exit="onCloseModal"
      />
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import useJwt from '@/auth/jwt/useJwt'
import 'swiper/css/swiper.css'
import ProfileAppointmentCard from './ProfileAppointmentCard.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    Swiper,
    SwiperSlide,
    ProfileAppointmentCard,
  },
  props: {
    appointments: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      patientData: JSON.parse(localStorage.getItem('patientData')),
      uRegister: '999999',
      pRegister: 'a027ec',
      loadingRegister: false,
      appointmentInfo: '',
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1440: {
            slidesPerView: 2.1,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 1.2,
            spaceBetween: 25,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1.1,
            spaceBetween: 15,
          },
          320: {
            slidesPerView: 1.1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
  created() {
    for (let i = 0; i < this.appointments.length; i += 1) {
      if (this.appointments[i].ok === 2) {
        this.appointments.splice(i, 1)
      }
    }
  },
  methods: {
    changeRoute() {
      this.$router.push({ name: 'member-appointment' })
    },
    showAppointmentInfo(appointmentInfo) {
      this.appointmentInfo = appointmentInfo
      this.$refs.appointmentModal.show()
    },
    onCloseModal(id) {
      this.$bvModal.hide(id)
    },
    confirmRegister() {
      // const today = new Date()
      // const currentDay = `0${today.getDate()}`.substr(-2)
      // const currentTimeHour = `0${today.getHours()}`.substr(-2)
      this.$swal({
        title: 'Are you sure?',
        text: `${this.$t('title.Confirm Register')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.checkEnrollFromFollowup()
        }
      })
    },
    checkEnrollFromFollowup() {
      this.loadingRegister = true
      const curDate = this.$helpers.getSQLCurDate()
      const curTimeHour = (new Date()).getHours()
      // Get All Appointment
      this.$http({
        url: 'http://api159.sriphat.com/api/Appointment/OldAppointmentByFilter',
        method: 'POST',
        data: {
          HN: this.patientData.hn,
          DateApp: curDate,
          DOC: '',
        },
      }).then(({ data }) => {
        // console.log('Appoinntment', data)
        const followUpLists = data
        for (let i = 0; i < data.length; i += 1) {
          if (data[i].ok === 0 || data[i].ok === 1) {
            // Check Appointment By Current Time
            const appointmentStart = this.$helpers.IntNull(followUpLists[i].time.substr(0, 2))
            const appointmentEnd = this.$helpers.IntNull(followUpLists[i].timE1.substr(0, 2))
            // console.log(curTimeHour, appointmentStart, appointmentEnd, (appointmentStart <= curTimeHour || (appointmentStart >= curTimeHour && appointmentEnd >= curTimeHour)))
            if (appointmentStart <= curTimeHour || (appointmentStart >= curTimeHour && appointmentEnd >= curTimeHour)) {
              // console.log('Get Doctor Schedule')
              this.$http({
                url: 'http://api159.sriphat.com/api/DoctorSchedule/GetOldSchedule',
                method: 'POST',
                data: {
                  DoctorCode: followUpLists[i].doc,
                  Location: followUpLists[i].room,
                  date: curDate,
                },
              // eslint-disable-next-line no-shadow
              }).then(({ data }) => {
                console.log('schedule', data.length, data)
                if (data.length > 0) {
                  let registerComplete = false
                  for (let s = 0; s < data.length; s += 1) {
                    // console.log(data[s])
                    // const periodHour = this.$helpers.IntNull(data[s].schd_st_time.substr(0, 2))
                    // const periodMin = this.$helpers.IntNull(data[s].schd_end_time.substr(3, 2))
                    // console.log(appointmentStart, periodHour, appointmentEnd, periodHour, data[s].schd_ser, followUpLists[i].schd_ser, (appointmentStart >= periodHour && appointmentEnd >= periodHour && data[s].schd_ser === followUpLists[i].schd_ser))
                    if (data[s].schd_term === followUpLists[i].term) {
                      // console.log('Register', data[s].schd_ser, followUpLists[i].schd_ser)
                      this.createEnrollFromFollowup(this.$helpers.IntNull(followUpLists[i].comefor), followUpLists[i].doc, followUpLists[i].room, followUpLists[i].appNote, 0, followUpLists[i].ser, followUpLists[i].schd_ser, followUpLists[i].inst_rowid, followUpLists[i].auxit_rowid)
                      registerComplete = true
                    }
                  }
                  if (!registerComplete) {
                    console.log('Register Out Schedule')
                    this.createEnrollFromFollowup(this.$helpers.IntNull(followUpLists[i].comefor), followUpLists[i].doc, followUpLists[i].room, followUpLists[i].appNote, 0, followUpLists[i].ser, 0, followUpLists[i].inst_rowid, followUpLists[i].auxit_rowid)
                  }
                } else {
                  console.log('Register No Schedule')
                  this.createEnrollFromFollowup(this.$helpers.IntNull(followUpLists[i].comefor), followUpLists[i].doc, followUpLists[i].room, followUpLists[i].appNote, 0, followUpLists[i].ser, 0, followUpLists[i].inst_rowid, followUpLists[i].auxit_rowid)
                }
              }).catch(error => {
                console.log('GetOldSchedule', error)
              })
            }
          }
        }
        // console.log(this.registerList)
      }).catch(error => {
        console.log('Get Appointment CurDate', error)
      })
      // Close Modal
      // setTimeout(() => this.$emit('exit', 'appointmentModal'), 3000)
      setTimeout(() => this.$router.go(0), 3000)
    },
    createEnrollFromFollowup(comeFor, doctorCode, clinic, note, scheduleID, appointmentID, oldScheduleID, payorID, planID) {
      this.$http({
        url: 'http://api159.sriphat.com/api/LoginPatient/login-Employee',
        method: 'POST',
        data: {
          Username: this.uRegister,
          Password: this.pRegister,
        },
      }).then(({ data }) => {
        useJwt.setToken(data.token)
        // Get Clinic ID
        let clinicID = 0
        this.$http({
          url: `http://phrapi.sriphat.com/api/Customer_Health_Record_/ClinicIDByClinicCode/${clinic}`,
          method: 'GET',
          data: {},
        // eslint-disable-next-line no-shadow
        }).then(({ data }) => {
          // console.log('ClinicID', clinic, data)
          clinicID = data
          this.$http({
            url: 'http://api159.sriphat.com/api/DoctorSchedule/GetDoctorCodeID',
            method: 'POST',
            data: {
              DocCode: doctorCode,
              ClinicCode: clinic,
            },
          // eslint-disable-next-line no-shadow
          }).then(({ data }) => {
            // console.log(data)
            this.$http({
              url: 'http://api159.sriphat.com/api/Enroll/OldEnroll',
              method: 'POST',
              data: {
                Service_Event: {
                  ComeFor: comeFor,
                  DoctorCodeID: data,
                  ClinicID: clinicID,
                  PayorID: payorID,
                  PlanID: planID,
                  ServiceType: 2,
                  EnrollNote: note,
                },
                ScheduleID: scheduleID,
                QueueNum: '',
                QueueType: 'OPD',
                HN: this.patientData.hn,
                AppointmentID: appointmentID,
                OldScheduleID: oldScheduleID,
              },
            // eslint-disable-next-line no-shadow
            }).then(({ data }) => {
              console.log(data)
            }).catch(error => {
              console.log('Register', error)
            })
          }).catch(error => {
            console.log('GetDoctorCodeID', error)
          })
        }).catch(error => {
          console.log('ClinicIDByClinicCode', error)
        })
      }).catch(error => {
        console.log('Login', error)
      })
    },
  },
}
</script>
