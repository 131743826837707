<template>
  <b-row
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <!-- Change View -->
    <b-col
      v-if=" patientMain !== '' && patientMain !== null && patientInfo !== '' || patientInfo !== null"
      cols="12"
    >
      <profile-view-as
        v-if="patientMain.idCard !== patientInfo.idCard"
      />
    </b-col>

    <!-- Header -->
    <b-col
      sm="12"
      md="6"
    >
      <profile-header
        v-if="showHeaderData"
        :patient-info="patientInfo"
        :visit-info="visitInfo"
      />
    </b-col>

    <!-- Today -->
    <b-col
      v-if="!loadToday"
      sm="12"
      md="6"
    >
      <b-overlay
        :show="loadToday"
      >
        <section id="profile-today">
          <profile-today :visit-info="visitInfo" />
        </section>
      </b-overlay>
    </b-col>

    <!-- Drug Allergy -->
    <b-col
      v-if="profileData.drugAllergy.length !== 0"
      cols="12"
    >
      <profile-drug-allergy
        :drug-allergy="profileData.drugAllergy"
      />
    </b-col>

    <!-- Appointment -->
    <b-col
      v-if="profileData.appointments.length !== 0"
      sm="12"
      md="6"
    >
      <b-overlay
        :show="loadingAppointment"
      >
        <section id="profile-appointment">
          <profile-appointment
            :appointments="profileData.appointments"
          />
        </section>
      </b-overlay>
    </b-col>

    <!-- Medical History -->
    <b-col
      sm="12"
      md="6"
    >
      <b-overlay
        :show="loadingMedical"
      >
        <section
          id="profile-medical-history"
        >
          <profile-medical-history
            :medical-histories="profileData.medicalHistory"
          />
        </section>
      </b-overlay>
    </b-col>

    <!-- Check up Report -->
    <!-- <b-col
      sm="12"
      md="6"
    >
      <b-overlay
        :show="patientInfo.idCard===''"
      >
        <section
          id="profile-checkup"
        >
          <profile-checkup />
        </section>
      </b-overlay>
    </b-col> -->

    <!-- Vital Sign -->
    <b-col
      cols="12"
      md="6"
    >
      <b-overlay
        :show="loadingVitalBodyMass"
      >
        <section
          id="profile-vital-sign"
        >
          <profile-vital-sign-body-mass
            :vital-sign-body-mass="profileData.vitalSignBodyMass"
          />
        </section>
      </b-overlay>
    </b-col>
    <b-col>
      <b-overlay
        :show="loadingVitalBodyMass"
      >
        <b-row v-if=" patientMain !== '' && patientMain !== null && patientInfo !== '' || patientInfo !== null">
          <b-col
            cols="12"
            md="6"
          >
            <b-card
              v-if="patientMain.idCard === patientInfo.idCard"
              v-b-modal.ratingModal
              class="text-center"
              border-variant="primary"
            >
              <feather-icon
                icon="GridIcon"
                class="mr-50 text-primary"
              />
              <strong class="align-middle text-primary">{{ t('feedback.feedbackPHR') }}</strong>
            </b-card>
            <b-card
              v-else
              class="text-center"
              border-variant="primary"
            >
              <feather-icon
                icon="GridIcon"
                class="mr-50 text-primary"
              />
              <strong class="align-middle text-primary">{{ t('feedback.feedbackPHR') }}</strong>
            </b-card>
          </b-col>
          <b-col>
            <b-card
              class="text-center"
              border-variant="success"
            >
              <a
                href="https://voc.sriphat.com/th/voc"
                target="_blank"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  class="mr-50 text-success"
                />
                <strong class="align-middle text-success">{{ t('feedback.feedbackSP') }}</strong>
              </a>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </b-col>
    <b-modal
      id="ratingModal"
      centered
      hide-footer
      size="md"
      :title="t('rating.rate exp')"
    >
      <profile-rating @rating="closeRating" />
    </b-modal>
    <b-col
      v-if="!loadToday"
      cols="12"
      sm="6"
    >
      <profile-wi-fi :visit-info="visitInfo" />
    </b-col>
  </b-row>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import profileStoreModule from './profileStoreModule'
import ProfileHeader from './ProfileHeader.vue'
import ProfileToday from './ProfileToday.vue'
import ProfileAppointment from './ProfileAppointment.vue'
import ProfileMedicalHistory from './ProfileMedicalHistory.vue'
import ProfileVitalSignBodyMass from './ProfileVitalSignBodyMass.vue'
import ProfileRating from './ProfileRating.vue'
import ProfileViewAs from './ProfileViewAs.vue'
import ProfileWiFi from './ProfileWiFi.vue'
import ProfileCheckup from './ProfileCheckup.vue'
import ProfileDrugAllergy from './ProfileDrugAllergy.vue'

export const sortCompare = key => (a, b) => {
  const fieldA = a[key]
  const fieldB = b[key]

  let comparison = 0
  if (fieldA > fieldB) {
    comparison = -1
  } else if (fieldA < fieldB) {
    comparison = 1
  }
  return comparison
}

export default {
  components: {
    ProfileHeader,
    ProfileToday,
    ProfileAppointment,
    ProfileMedicalHistory,
    ProfileVitalSignBodyMass,
    ProfileRating,
    ProfileViewAs,
    ProfileWiFi,
    ProfileCheckup,
    ProfileDrugAllergy,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-profile'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, profileStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      visitInfo: null,
      // patientView: '',
      patientMain: JSON.parse(localStorage.getItem('patientTempData')),
      patientInfo: '',
      moduleData: '',
      idCard: '',
      showHeaderData: false,
      loadingToday: true,
      loadingAppointment: true,
      loadingMedical: true,
      loadingVitalBodyMass: true,
      profileData: {
        latestPayments: [
          {
            Date: 'xx/xx/xxxx',
            Clinic: 'Clinic',
            Doctor: 'Dr. xxx xxxx',
            Total: 'xxx',
          },
        ],
        appointments: [],
        enrollForm: {},
        enrolls: [
          {
            // eslint-disable-next-line global-require
            avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
            name: 'Mr.John Smith',
            enrollDate: '14/01/2077',
            detail: 'I had a Superb head ache, its really hurt me \nI wanna make an appointment.',
            status: 'Walting',
            queue: '',
            doctor: '',
            clinic: '',
            treatmentDate: '',
            plan: '',
            payor: '',
            serviceType: '',
          },
        ],
        medicalHistory: [],
        vitalSignBodyMass: {},
        drugAllergy: [],
      },
    }
  },
  watch: {
    '$route.path': 'checkProfile',
  },
  async created() {
    // if (this.$route.param) {
    //   this.moduleData = this.$route.param.module
    // } else {
    //   this.moduleData = 'home'
    // }
    await this.checkProfile()
  },
  methods: {
    closeRating() {
      this.$bvModal.hide('ratingModal')
    },
    addNewEnrollInEnrollForm(enrollDataForm) {
      // console.log('ProfileMain', enrollDataForm)
      this.profileData.enrolls.unshift(enrollDataForm)
      this.$bvToast.toast('Enroll success.', {
        title: 'Enroll',
        variant: 'success',
        solid: true,
      })
    },
    async checkProfile() {
      // console.log(this.patientMain)
      if (localStorage.getItem('patientData') === null && localStorage.getItem('accountData') === null) {
        this.$router.replace('/profileCreateAccount')
      }
      this.showHeaderData = false
      if (localStorage.getItem('patientData') === null) {
        if (localStorage.getItem('patientTempData') === null) {
          this.patientInfo = JSON.parse(localStorage.getItem('accountData'))
          this.idCard = ''
        } else {
          this.patientInfo = JSON.parse(localStorage.getItem('patientTempData'))
          this.idCard = this.patientInfo.idCard
        }
        // this.loadProfile()
        // console.log('patientInfo', this.patientInfo)
        // this.idCard = this.patientInfo.idCard
        // console.log(this.idCard)
        let urlApi = ''
        if (this.idCard === '' || this.idCard === null || this.idCard === undefined) {
          // console.log('idCard is Null')
          this.$http({
            url: `http://phrapi.sriphat.com/api/UserAuthen/CustomerAccountByID/${this.patientInfo.accountID}`,
            method: 'GET',
            data: { },
          }).then(({ data }) => {
            urlApi = `http://phrapi.sriphat.com/api/Patient/GetPatientDetail/null/${data.hn}`
            this.loadProfile(urlApi)
          }).catch(error => {
            console.log('CustomerAccountByID', error)
          })
        } else {
          urlApi = `http://phrapi.sriphat.com/api/Patient/GetPatientDetail/${this.idCard}/null`
          await this.loadProfile(urlApi)
        }
      } else {
        // this.patientInfo = JSON.parse(localStorage.getItem('accountData'))
        // console.log('No patientData')
        this.patientInfo = JSON.parse(localStorage.getItem('patientData'))
        // this.patientView = JSON.parse(localStorage.getItem('patientTempData'))
        // console.log(this.patientInfo, this.patientView)
        this.showHeaderData = true
        await this.loadAllData()
      }
    },
    loadProfile(urlApi) {
      // console.log('loadProfile')
      this.$http({
        url: urlApi,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        if (data.length > 0) {
          // console.log(JSON.stringify(data[0]))
          localStorage.setItem('patientData', JSON.stringify(data[0]))
          localStorage.setItem('patientTempData', JSON.stringify({ idCard: data[0].idCard }))
          this.patientMain = JSON.parse(localStorage.getItem('patientTempData'))
          // this.patientView = JSON.parse(localStorage.getItem('patientTempData'))
          // console.log(this.patientView)
          // eslint-disable-next-line prefer-destructuring
          this.patientInfo = data[0]
          this.showHeaderData = true
          this.loadAllData()
        }
      }).catch(error => {
        console.log('loadProfile', error)
      })
    },
    loadAllData() {
      this.loadToday()
      this.loadAppointment()
      this.loadMedicalList()
      this.loadVitalSignBodyMass()
      this.loadPatientImage()
      this.loadDrugAllergy()
    },
    loadToday() {
      // const testHn = '0132741'
      this.$http({
        // url: `http://api159.sriphat.com/api/Enroll/VisitSlipDetailDecode/${testHn}`,
        url: `http://api159.sriphat.com/api/Enroll/VisitSlipDetailDecode/${this.patientInfo.hn}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        // console.log(data)
        this.visitInfo = data
        if (data.listDoctorDetail != null) {
          this.loadToday = false
        }
      }).catch(error => {
        console.log('loadVisitDetail', error)
      })
    },
    loadAppointment() {
      this.profileData.appointments = []
      this.loadingAppointment = true
      this.$http({
        // url: `http://192.168.100.56/api/Appointment/listOldAppointment/${this.patientInfo.hn}`,
        url: `http://api159.sriphat.com/api/Appointment/listOldAppointment/${this.patientInfo.hn}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        // console.log('load', data)
        this.loadingAppointment = false
        this.profileData.appointments = data
      }).catch(error => {
        console.log('loadAppointment', error)
      })
    },
    loadMedicalList() {
      this.profileData.medicalHistory = []
      this.loadingMedical = true
      this.$http({
        // url: `http://192.168.100.56/api/Customer_Health_Record_/listcustomerEMR/${this.patientInfo.hn}/0`,
        url: `http://phrapi.sriphat.com/api/Customer_Health_Record_/listcustomerEMR/${this.patientInfo.hn}/0`,        
        // url: `http://phrapi.sriphat.com/api/Customer_Health_Record_/listcustomerEMR/${this.patientInfo.hn}/0`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        this.loadingMedical = false
        this.profileData.medicalHistory = data.enrollHistories
      }).catch(error => {
        console.log('loadMedicalList', error)
      })
    },
    loadVitalSignBodyMass() {
      this.profileData.vitalSignBodyMass = {}
      this.loadingVitalBodyMass = true
      this.$http({
        url: `http://phrapi.sriphat.com/api/Customer_Health_Record_/VitalSignAndBodyMass/${this.patientInfo.hn}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        this.loadingVitalBodyMass = false
        this.profileData.vitalSignBodyMass = data
        // Sort Data
        const tempTempInfos = data.tempInfos
        this.profileData.vitalSignBodyMass.tempInfos = tempTempInfos.sort(sortCompare('dateProvider'))
        const tempPulseInfos = data.pulseInfos
        this.profileData.vitalSignBodyMass.pulseInfos = tempPulseInfos.sort(sortCompare('dateProvider'))
        const tempDiastolicInfos = data.diastolicInfos
        this.profileData.vitalSignBodyMass.diastolicInfos = tempDiastolicInfos.sort(sortCompare('dateProvider'))
        const tempSystolicInfos = data.systolicInfos
        this.profileData.vitalSignBodyMass.systolicInfos = tempSystolicInfos.sort(sortCompare('dateProvider'))
        const tempBodyMassInfos = data.bodyMassInfos
        this.profileData.vitalSignBodyMass.bodyMassInfos = tempBodyMassInfos.sort(sortCompare('dateProvider'))
        this.loadingVitalBodyMass = false
      }).catch(error => {
        console.log('loadVitalSignBodyMass', error)
      })
    },
    loadPatientImage() {
      this.$http({
        url: `http://phrapi.sriphat.com/api/Patient/PatientImage/${this.patientInfo.hn}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        // console.log('Load Image')
        localStorage.setItem('patientImage', data)
        // this.ptImage = `data:image/jpeg;base64, ${data}`
      }).catch(error => {
        console.log('loadPatientImage', error)
      })
    },
    loadDrugAllergy() {
      this.profileData.drugAllergy = []
      this.$http({
        url: `http://phrapi.sriphat.com/api/Customer_Health_Record_/PatientDrugAllergy/${this.patientInfo.hn}`,
        // url: 'http://phrapi.sriphat.com/api/Customer_Health_Record_/PatientDrugAllergy/0611377',
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        // console.log(data)
        this.profileData.drugAllergy = data
      }).catch(error => {
        console.log('loadDrugAllergy', error)
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
