<template>
  <div>
    <h5 class="text-nowrap">
      {{ t('title.SayHi') }}
    </h5>
    <b-card
      class="profile-header"
      stretch
      overlay
      :img-src="require('@/assets/images/banner/phr-banner-4.png')"
      img-width="100"
      img-height="220"
    >
      <!-- img-src="https://images.unsplash.com/photo-1662013048334-5ecc902898b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=736&q=80" -->
      <b-card-text>
        <b-row>
          <b-col cols="9">
            <h6
              style="text-shadow: 0 0 2px #000000;"
              class="text-light pt-2"
            >
              {{ t('patient.hn') }} <small>{{ patientInfo.hn }}</small>
            </h6>
            <h4
              class="text-light"
              style="text-shadow: 0 0 2px #000000; white-space: nowrap;  width: 100%;  overflow: hidden;  text-overflow: ellipsis;"
            >
              {{ patientInfo.prefix }}{{ patientInfo.firstName }} {{ patientInfo.lastName.replaceAll('@','').replaceAll('#','').replaceAll('*','').replaceAll('$','') }}
            </h4>
            <h6
              style="text-shadow: 0 0 2px #000000;"
              class="text-light"
            >
              {{ patientInfo.mainContactNumber }}
            </h6>
          </b-col>
          <!-- <b-col
            cols="3"
            class="text-right"
          >
            <b-img
              :src="require('@/assets/images/logo/sp_logo_white.png')"
              class=""
              height="45"
              alt="Toast image"
            />
            <br>
          </b-col> -->
        </b-row>
        <b-row class="mt-3">
          <b-col cols="6">
            <b-img
              v-b-modal.modal-lg
              :src="require('@/assets/images/icons/qr-code.png')"
              class=""
              height="49"
              alt="Toast image"
            />
          </b-col>
          <b-col
            cols="6"
            class="text-right"
          >
            <small
              style="text-shadow: 0 0 2px #000000;"
              class="text-light"
            >
              {{ t('patient.dob') }}
            </small>
            <br>
            <small
              class="text-light"
              style="text-shadow: 0 0 2px #000000;"
            >
              {{ $helpers.getFullDate(patientInfo.born, $i18n.locale) }}
            </small>
          </b-col>
        </b-row>
        <b-modal
          id="modal-lg"
          :hide-footer="true"
          :hide-header="true"
          centered
          size="xs"
        >
          <b-card-text>
            <profile-card
              :patient-info="patientInfo"
            />
          </b-card-text>
        </b-modal>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import ProfileCard from './ProfileCard.vue'

export default {
  components: {
    ProfileCard,
    // eslint-disable-next-line vue/no-unused-components
  },
  directives: {
    Ripple,
  },
  props: {
    patientInfo: {
      type: Object,
      default: () => {},
    },
    visitInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
}
</script>
